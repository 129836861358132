<p-dialog [(visible)]="VenteDialog" [style]="{width: '750px' }"
[breakpoints]="
        {
            '800px': '30vw',  
            '650px': '100vw' 
        }"
header="Vente Article" [modal]="true" class="p-fluid" (onHide)="hide()" >
    <!-- <p-toast></p-toast> -->
  
    <ng-template pTemplate="content"  >
      <div >
        <div class="field-dropdown "  >
                     <!-- [filter]="customFilter" -->

          <!-- <p-dropdown   [options]="articles" optionLabel="code_article_generique"
           [(ngModel)]="selectedArticle" [showClear]="true" 
           placeholder="Selectionner un Articles" 
           (onChange)="getSelectedArticles()"></p-dropdown> -->


           <p-autoComplete [(ngModel)]="selectedArticle" styleClass="myClass"  [appendTo]="'body'" [ngClass]="{'empty-input': !selectedArticle && emptyarticle}"
           [suggestions]="filtredArticle" (onKeyUp)="getwithkeyup($event)" (completeMethod)="filterArticles($event)" placeholder="Articles" (onSelect)="getSelectedArticles()"
					field="code_article_generique" [dropdown]="true"   
					></p-autoComplete>
	
          <!-- <a routerLink="/pages/list-products/list-products"><i class="pi pi-filter custom-icon"></i></a> -->
          <br>
          <!-- <ng-container  *ngFor="let errorMessage of errorMessages" > -->
            <!-- <p-messages>
            </p-messages> -->
      <!-- </ng-container> -->
   <!-- <ng-container >
                <p-message [severity]="errorMessage.severity" [summary]="errorMessage.summary" [detail]="errorMessage.detail"></p-message>
              </ng-container> -->

      </div>
      <div class="container-table">
        <div class="col-12 lg:col-12">
          <div class="card orders-table">

        <p-table  dataKey="id" [value]="test"  styleClass="p-datatable-gridlines" >
         
             <ng-template pTemplate="header">
               <tr>
                <ng-container *ngIf="selectedArticle">

                 <th class="custom-th">COULEURS/TAILLES</th>

                 <ng-container *ngFor="let size of TailleArray">
                  <th  class="size-th">{{size}}</th>
                 </ng-container>
                </ng-container>         
               </tr>
              
             </ng-template>

             <ng-template pTemplate="body" let-test >                                               
              <ng-container *ngFor="let color of ColorsArray; let i = index">
                <ng-container *ngIf="selectedArticle">

               <tr>
                 <td style="background-color: #ffffff" >{{color}}</td>
                 <ng-container *ngFor="let size of TailleArray; let j= index">
                  <td #td 
                  [attr.id]="'td-' + i + '-' + j"
                  [attr.contenteditable]="isDisabled(size, color) ? null : true"
                   contenteditable="true"
                   pattern="^-?[0-9]*"
                   (keypress)="onKeyPress($event)" 
                   (input)="onCellInput($event, color, size,i,j)"
                   [style.background-color]="isDisabled(size, color) ? '#E2E8F0' : (isInvalidCell[i]?.[j] ? '#F5CCCC' : 'rgba(59, 130, 246, 1)')" 
                   [style.font-weight]="isDisabled(size, color) ? 'normal' : 'bold'"
                   [style.font-size]="isDisabled(size, color) ? '12px' : '14px'"
                   [style.color]="isDisabled(size, color) ? 'black' : 'white'"
                   ></td>
                  </ng-container>

                 <!-- <td style="min-width: 3rem;">{{ test.id }}</td>
                 <td style="min-width: 3rem;" style="background-color: #E2E8F0">{{ test.Jaune }}</td>
                 <td style="min-width: 3rem;" style="background-color: #E2E8F0">{{ test.Marron }}</td> -->
   
   
               </tr>
              </ng-container>
              </ng-container>
             </ng-template>
             
          </p-table>

           </div>
          </div> 
        </div>
        <div class="field-container">
          <div class="field1">
            <label htmlFor="prixHt">Prix HT</label>
            <input pInputText type="text" [(ngModel)]="prixht" [disabled]="true" />
          </div>
        
          <div class="field1">
            <label htmlFor="prixTtc">Prix TTC</label>
            <input pInputText type="text" [(ngModel)]="Prix" [disabled]="true" />
          </div>
        
          <div class="field2">
            <label htmlFor="remise">Remise</label>
            <input pInputText type="number" [(ngModel)]="Remise" min="0" max="100"(ngModelChange)="updateValues()"
            (input)="validateInput()" (keyup)="validateRemise($event)" />
          </div>
        </div>
      </div>
  </ng-template>
  <ng-template pTemplate="footer">
  <button pButton pRipple label="Valider et Fermer" icon="pi pi-times" class="p-button-text" (click)="hideDialog(td)"></button>
  <button pButton pRipple [disabled]="disableSaveButton" label="Valider et Continuer" icon="pi pi-check" class="p-button-text" (click)="sendVente(td)"></button>
  </ng-template>
  </p-dialog>