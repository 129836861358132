import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
			<div class="logo-text">
				<img src="https://backendengin.cegidretail.shop/uploads/Whats_App_Image_2024_01_25_a_15_26_59_2098fc6d_7cf9878cbf.jpg" />
				<div class="text">
					<!-- <span>Premium Application Template</span> -->
				</div>
			</div>
			<div class="icons">
				<div class="icon icon-hastag">
					<i class="pi pi-home"></i>
				</div>
				<div class="icon icon-twitter">
					<i class="pi pi-globe"></i>
				</div>
				<div class="icon icon-prime">
					<i class="pi pi-bookmark"></i>
				</div>
			</div>
        </div>
    `
})
export class AppFooterComponent {

}
