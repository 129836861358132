import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AchatsService } from 'src/app/demo/service/achats.service';
import { VenteService } from 'src/app/demo/service/vente.service';
import { RoundPipe } from 'src/app/round.pipe';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-add-vente-dialog',
  templateUrl: './add-vente-dialog.component.html',
  styleUrls: ['./add-vente-dialog.component.scss']
})

export class AddVenteDialogComponent implements OnInit {
  @Output() dataSent = new EventEmitter<any>();
  Masques
  selectedArticle
  articles
  variationsArticle
  ;
  Prix
  prixht
  Remise:number=0
  ColorsArray: any[] = [];
  TailleArray: any[] = [];
  newVente
  achatVariations:any[]=[]
  isArticleSelectionDisabled: boolean 
  VenteDialog
  stocks
  DetailsVente
  filtredArticle
  StockVariations
  errorMessages: any[] = [];
  isInvalidCell: boolean [][] = [];;
  cellValue: number
  disableSave:boolean=false
  previousCellContent ; 
  Invalid:boolean=false
  emptyarticle:boolean=false


  @HostBinding('style.background-color') backgroundColor: string = '#ffffff';
editingCell: { row: number, col: number } | null = null;
  // invalidCells: boolean[][]= new Array(this.ColorsArray.length).fill(false).map(() => new Array(this.TailleArray.length).fill(false)); ;


test = [
  { id: 'Q35', Marron: '', Jaune: '' },
  // { id: 'Q36', Marron: '', Jaune: '' },
  // Add more data as needed
];

  constructor(private achatService:AchatsService,private messageService: MessageService,private venteService:VenteService,private Roundpipe:RoundPipe) {
       this.Remise=0
   }

  ngOnInit(): void {
    this.getMasques()
    this.getArticles()
    this.getStock()

  }
  showDialog(data?){
    this.VenteDialog= true
    this.emptyarticle=false
    console.log("Details vente",data)
    this.DetailsVente=data
  if(this.articles && this.stocks){
    this.updateStocks(data)
  }

  }

  async updateStocks(data) {
    if (data.store) {
      // console.log(data.store)
      let filteredStocks = [];
  this.articles.forEach(article => {
  article.variations.forEach(variation => {
    const variationId = variation.id;

    filteredStocks.push(...this.stocks.filter(stock => {
      return  stock.variation.id === variationId  && stock.store === data.store.name;
    }));

  })

})
 console.log("stock variation", filteredStocks);

  //     for (let article of this.articles) {
  //       for (let variation of article.variations) {
  //         const res = await this.venteService.getStockVariations(variation.id, data.store.id);
  
  //         // console.log("stock variation", res);
  
  //         const stockData = res.data.map(item => ({
  //           id: item.id,
  //           quantite: item.attributes.quantity,
  //           Variation: {
  //             id: item.attributes.variation.data.id,
  //             code_article_generique: item.attributes.variation.data.attributes.code_article_generique,
  //             color: {
  //               id: item.attributes.variation.data.attributes.color.data.id,
  //               name: item.attributes.variation.data.attributes.color.data.attributes.name
  //             },
  //             taille: {
  //               id: item.attributes.variation.data.attributes.taille.data.id,
  //               name: item.attributes.variation.data.attributes.taille.data.attributes.name
  //             }
  //           }
  //         }));
  
  //         stocks.push(...stockData);
  //       }
  //     }
  
  //     this.stocks = stocks;
  //     console.log('stock variations', this.stocks);
    }
  }
  
  hide(){
    this.VenteDialog=false
    this.achatVariations=[]
this.Prix=null
this.prixht=null
this.Remise=0
this.selectedArticle=''
  }
  hideDialog() {
//      if(this.Invalid){
//     this.disableSave=true
//  this.messageService.add({
//   severity: 'error',
//   summary: '',
//   detail: 'Verifier quantité ' ,
// })
//   }
//   else{
// }
if(this.selectedArticle){
  if(this.achatVariations && this.achatVariations.length>0){
    this.newVente={
      'VenteVariations':this.achatVariations,
      'prixTTC':this.Prix,
      'prixHT':this.prixht,
      'remise':this.Remise,
      'ref':this.selectedArticle.code_article_generique,
      'designation':this.selectedArticle.designation,
      'article':this.selectedArticle
  
    }
  console.log('new ticket',this.newVente)
  this.dataSent.emit(this.newVente)
    this.VenteDialog=false
    this.achatVariations=[]
  this.Prix=null
  this.prixht=null
  this.Remise=0
  this.selectedArticle=''
  }
 else{
  if(this.variationsArticle && this.variationsArticle.length>0 && this.ColorsArray && this.ColorsArray.length>0 && this.TailleArray.length>0  && this.TailleArray ){

  this.messageService.add({
      severity: 'error',
      summary: '',
      detail: 'Ajouter Quantité  ' ,
    })
  }
 }
}
else{
  // this.hide()
  this.emptyarticle=true

}
  

  }

 async  getwithkeyup(event){
    console.log("key press article", event.target.value)
     await this.venteService.getArticles(event.target.value).then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
          code_fournisseur:items?.attributes?.code_fournisseur,
          designation:items?.attributes?.designation,
          sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
          collection:items?.attributes?.collection.data?.attributes?.name,
          rayon:items?.attributes?.rayon.data?.attributes?.name,
          prix_achat_ttc:items?.attributes?.prix_achat_ttc,
          prix_vente_ttc:items?.attributes?.prix_vente_ttc,
          prix_achat_ht:items?.attributes?.prix_achat_ht,
          prix_vente_ht:items?.attributes?.prix_vente_ht,
          fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items?.attributes?.variations.data
        })
        )
      })
    console.log(this.articles)
    const matchingArticle = this.articles.find(article => article.code_article_generique === event.target.value);
    
      if (matchingArticle) {
    this.selectedArticle=matchingArticle
      } else {
        this.achatVariations=[]
        this.variationsArticle = [];
        this.ColorsArray = [];
        this.TailleArray = [];
        this.prixht=0
        this.Prix=0
        this.Remise=0
    
      }
      }
  async getArticles(){
    await this.venteService.getArticles().then(res=>
      {console.log(res)
      this.articles=res?.data.map(items=>
        ({
          id: items.id,
          code_article_generique:items?.attributes?.code_article_generique,
          code_fournisseur:items?.attributes?.code_fournisseur,
          designation:items?.attributes?.designation,
          sous_famille:items?.attributes?.sous_famille.data?.attributes?.name,
          collection:items?.attributes?.collection.data?.attributes?.name,
          rayon:items?.attributes?.rayon.data?.attributes?.name,
          prix_achat_ttc:items?.attributes?.prix_achat_ttc,
          prix_vente_ttc:items?.attributes?.prix_vente_ttc,
          prix_achat_ht:items?.attributes?.prix_achat_ht,
          prix_vente_ht:items?.attributes?.prix_vente_ht,
          fournisseur:items?.attributes?.fournisseur.data?.attributes?.name,
          nature:'',
          Unite_de_stock:'',
          variations:items?.attributes?.variations.data
        })
        )
      })
      console.log(this.articles)
  }

  validateRemise(event){
    // console.log(event)
    const inputValue = event.target.value;
    console.log(inputValue)
  
    // Ensure the input is a non-negative number between 0 and 100
    const pattern = /^(100|[1-9]?\d|0)$/;
    if (pattern.test(inputValue)) {
      this.Remise = parseInt(inputValue, 10);
      if (this.Remise === 100) {
        this.prixht = 0;
        this.Prix = 0;
      }
  
    } 
    else {
      this.Remise = 0;
    }
  }
  validateInput() {
    if (this.Remise < 0) {
      this.Remise = 0;
    } else if (this.Remise > 100) {
      this.Remise = 100;
    }
  }
  total : number  = 0;
  totalht : number  = 0;
  updateValues() {
    this.Prix = this.Remise == 0.0 ? this.total :  this.Roundpipe.transform((this.total * (100 - this.Remise)) / 100);
    this.prixht = this.Remise == 0.0 ? this.totalht : this.Roundpipe.transform((this.totalht * (100-this.Remise))/100 );
  }
  filterArticles(event: AutoCompleteCompleteEvent){
    let filtered: any[] = [];
  let query = event.query;

  for (let i = 0; i < (this.articles as any[]).length; i++) {
      let country = (this.articles as any[])[i];
      if (country.code_article_generique.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(country);
      }
  }

  this.filtredArticle = filtered;
  }
  async getStock() {
    try {
      const res = await this.achatService.getStocks();
      console.log(res);
  
      this.stocks = res.data.map(item => ({
        id: item.id,
        qte: item.attributes.quantity,
        article: item.attributes.product.data?.attributes.code_article_generique,
        store: item.attributes.store.data?.attributes.name,
        variation: {
          id:item.attributes.variation.data?.id,
          couleur: item.attributes.variation.data?.attributes.color.data?.attributes.name,
          taille: item.attributes.variation.data?.attributes.taille.data?.attributes.name
        }
      }));
  
      console.log("liste stocks",this.stocks);
    } catch (error) {
      console.error('Error fetching stocks:', error);
    }
  }
  
  getMasques(){
    this.achatService.getMasques().then(res=> {
      console.log(...res.data)
    this.Masques=res.data.map(item=>{return { name: item.attributes?.name, id: item.id, tailles:item.attributes?.tailles_masques };})
    // this.Tailles=res.data.attributes.tailles_masques.data
    console.log(this.selectedArticle)

    console.log(...this.Masques)
    })
  }
  async getSelectedArticles() {
    
    const Cag = this.selectedArticle.code_article_generique ;
    console.log('articleeeeeeeeeee',this.selectedArticle);
    console.log("variation article",Cag);
    this.totalstock = await this.venteService.getStocksTotal(Cag);
    console.log('total ',this.totalstock);
    this.achatVariations=[]
    this.variationsArticle = [];
    this.Prix = null;
    this.Remise=0
    this.ColorsArray = [];
    this.TailleArray = [];
  
    if (this.selectedArticle) {
      this.variationsArticle = this.selectedArticle.variations.map(item =>
        ({
          id: item.id,
          code_barre:item.attributes.code_barre,
          color: {
            colorName: item.attributes.color.data.attributes.name,
            colorId: item.attributes.color.data.id
          },
          taille: {
            tailleName: item.attributes.taille.data.attributes.name,
            TailleId: item.attributes.taille.data.id
          }
        }));
  
        this.Prix = this.Roundpipe.transform(this.selectedArticle.prix_vente_ttc);
        this.prixht = this.Roundpipe.transform(this.selectedArticle.prix_vente_ht);
        this.total = this.selectedArticle.prix_vente_ttc;
        this.totalht = this.selectedArticle.prix_vente_ht;
      console.log("variationsArticle",this.variationsArticle);


  
      const stockVariation = [];

for (const stock of this.stocks) {
  if (
    stock.article === this.selectedArticle.code_article_generique &&
    stock.store === this.DetailsVente.store.name
  ) {
    let found = false;

    for (const variation of this.variationsArticle) {
      if (
        variation.color.colorName === stock.variation.couleur &&
        variation.taille.tailleName === stock.variation.taille
      ) {
        found = true;
        break;
      }
    }

    if (found) {
      stockVariation.push(stock);
    }
  }
}
           console.log("stock variation",stockVariation);
           this.StockVariations=stockVariation

    }
    
    this.getDistinctColorsAndTailles();
  }
  
  customSort(a: string, b: string): number {
    // Extract the numeric part from strings
    const numA = parseInt(a.match(/\d+/)?.[0] || "0", 10);
    const numB = parseInt(b.match(/\d+/)?.[0] || "0", 10);
  
    // Compare the numeric parts
    if (numA !== numB) {
      return numA - numB; // Sort numerically in descending order
    }
  
    // If numeric parts are equal, compare the entire strings
    return a.localeCompare(b);
  }
getDistinctColorsAndTailles() {

  const uniqueColors = new Set<string>();
  const uniqueTailles = new Set<string>();

  this.variationsArticle.forEach(item => {
    uniqueColors.add(item.color.colorName);
    uniqueTailles.add(item.taille.tailleName);
  });

  this.ColorsArray = Array.from(uniqueColors).map(colorName => ( colorName ));
  this.TailleArray = Array.from(uniqueTailles).sort((a, b) =>this.customSort(a, b));
  ;


  console.log(this.ColorsArray)
  console.log(this.TailleArray)

}


findVariationId(size, color): any | undefined {
  const variation = this.variationsArticle.find(item =>
    item.taille.tailleName === size && item.color.colorName === color
  );
  return variation ? variation.id : undefined;
}
isCellInvalid(color, size,cellValue, cellIndex?): boolean {
  console.log(cellIndex);

  const stockItem = this.StockVariations.find(item => item.variation.couleur === color && item.variation.taille === size);
  return stockItem && cellValue > stockItem.qte;
}


clearErrorMessages(i?,j?) {
this.errorMessages = [];
this.messageService.clear();
// this.isInvalidCell[i][j] = false;

}
onCellInput(event: any, color: any, size: any ,i: number, j: number) {
  this.cellValue=0
  const idVariation=this.findVariationId(size,color)
  const variationObject = this.variationsArticle.find(variation => variation.id === idVariation);
  console.log(variationObject);

 const code_barre= this.variationsArticle.filter(variation => variation.id === idVariation);
  const cellValue = +event.target.innerText.trim();
  // this.previousCellContent=cellValue

  this.cellValue=cellValue
  console.log({'QTE': cellValue,'ID':idVariation,'code_barre':variationObject.code_barre,'Color':color,'Size': size});
  this.isInvalidCell[i] = this.isInvalidCell[i] || [];

  if (cellValue === 0) {
    this.backgroundColor = '#ffffff';
    console.log('Cell value is 0. Not creating a new item.');
    this.isInvalidCell[i][j] = false;
    this.Invalid=false

    this.clearErrorMessages(i, j);
    this.removeSavedValue(size, color);
    console.log("variation vente",this.achatVariations);
    console.log("INVALID CELL",this.isInvalidCell[i][j]);

    return;
  }

  const newItem={'QTE': cellValue,'ID':idVariation,'code_barre':variationObject.code_barre,'Color':color,'Size': size}
  
  // Remove existing saved value for the given size and color
  this.removeSavedValue(size, color);
  // Save the new item
  this.achatVariations.push(newItem);
  console.log("variation vente",this.achatVariations);

}

removeSavedValue(size: string, color: string) {
  const indexToRemove = this.achatVariations.findIndex(item =>
    item.Size === size && item.Color === color
  );
  
  if (indexToRemove !== -1) {
    this.achatVariations.splice(indexToRemove, 1);
  }
}

totalstock

onKeyPress(event: any) {
  // const inputChar = String.fromCharCode(event.charCode);
  // const currentValue = event.currentTarget.innerText;
  // if (currentValue === '' && inputChar === '-') {
  //   return;
  // }

  // if (!/[0-9]/.test(inputChar)) {
  //   event.preventDefault();
  // }
  // this.clearErrorMessages();
  console.log(event)
  const pattern = /^(?!0)[0-9]+$/; 
  const existingContent = event.target.innerText;
  const keyPressed = event.key;

  const inputValue = existingContent + keyPressed;

  if (!pattern.test(inputValue)) {
    console.log('Invalid value:', inputValue);
      event.preventDefault();

  } else {
    console.log('Valid value:', inputValue);
  }

}

isDisabled(size, color): boolean {
  // console.log(size,color)

  const variation = this.variationsArticle.find(item =>
    item.taille.tailleName === size && item.color.colorName === color
  );
  if(variation){

    return false
  }
  else {

    return true}
  // console.log(variation)
  // return variation !== undefined;
}

sendVente(){
    console.log(this.selectedArticle)
//   if(this.Invalid){
//     this.disableSave=true
//  this.messageService.add({
//   severity: 'error',
//   summary: '',
//   detail: 'Verifier quantité ' ,
// })
//   }
//   else{
   // }
   console.log(this.achatVariations)
   if(!(this.Prix>0 && this.prixht>0)){
        this.ColorsArray = [];
    this.TailleArray = [];
       this.messageService.add({
      severity: 'error',
      summary: '',
      detail: 'Sectionner article' ,
    })
   }else
{ 
    if(this.selectedArticle  ){
    if(this.achatVariations && this.achatVariations.length>0){
console.log(1)
    this.disableSave=false

    this.newVente={
      'VenteVariations':this.achatVariations,
      'prixTTC':this.Prix,
      'prixHT':this.prixht,
      'remise':this.Remise,
      'ref':this.selectedArticle.code_article_generique,
      'designation':this.selectedArticle.designation,
      'article':this.selectedArticle}
console.log('new ticket',this.newVente)
this.dataSent.emit(this.newVente)
this.achatVariations=[]
this.Prix=''
this.prixht=null
this.Remise=0
this.selectedArticle=''
    }
else{
  if(this.variationsArticle && this.variationsArticle.length>0 && this.ColorsArray && this.ColorsArray.length>0 && this.TailleArray.length>0  && this.TailleArray ){
console.log(2)
  this.messageService.add({
      severity: 'error',
      summary: '',
      detail: 'Ajouter Quantité  ' ,
    })
  }
 }
  }
  else{
      console.log(3)
    this.emptyarticle=true

    // this.hide()
  }}
  
 

}

}
