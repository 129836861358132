import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class VenteService {
  private isToastShown: boolean = false;

  constructor(private http: HttpClient, private messageService: MessageService) { }
  
     //**************************************handle errrors***************************************** */
     private handleApiError(error) {
      // Handle API error status here
      console.error('API call failed:', error);
    
      if (error.status !== 200) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Erreur inattendue' });
        this.isToastShown = true;
        setTimeout(() => {
          this.isToastShown = false;
        }, 5000);
      } 
      
    }
    
    
      getStocksTotal(ref: string): Promise<number> {
    return new Promise<any>((resolve, reject) => {
      const url = `${environment.url}/getStock?&order=DESC&queryName=getProducts&fltrproduct=${ref}`;
      
      this.http.get<any>(url)
        .toPromise()
        .then(response => {
          console.log(response.data);
          // Assuming that response contains a property 'data' with a property 'total_quantity'
          resolve(response.data[0].total_quantity);
        })
        .catch(error => {
          // Handling errors
          this.handleApiError(error);
          reject(error);
        });
    });
  }
  
       //******************************************************************************* */
  
  async getStockVariations(idVariation,idStore) {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/stocks?populate=variation&populate=variation.color&populate=variation.taille&filters[variation][id][$eq]=${idVariation}&filters[store][id][$eq]=${idStore}`;
    //   if (idVariation ) {
    //     url += `&filters[variation][id][$eq]=${idVariation}`;
    // }
    
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err;           
        });
    });
    return promise;
  }
  async getArticles(data?) {
    let promise = new Promise<any>((resolve) => {
        
       const searchTerm = data ? data : "";
      const attribute = "code_article_generique";

      let url = `${environment.url}/products?filters[${attribute}][$containsi]=${searchTerm}&populate=sous_famille&populate=variations
      &populate=variations.color&populate=variations.taille
      &populate=collection&populate=rayon&populate=fournisseur`;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }
  getEtablissements() {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/etablissements?populate=* `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }
  getClients() {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/clients?populate=* `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err; 
                });
    });
    return promise;
  }
  getStocks() {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/stocks?populate=product&populate=product.fournisseur&populate=store&populate=variation&populate=variation.color&populate=variation.taille `;
      this.http
        .get(url)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }
  createTicket(data) {
    let promise = new Promise<any>((resolve) => {
     
      let url = `${environment.url}/createVente?`;
      this.http
        .post(url,data)
        .toPromise()
  
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.handleApiError(err);
          throw err; 
        });
    });
    return promise;
  }


  updateTicket(data, id: string) {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/ventes/${id}`; 
      this.http
        .put(url, data)
        .toPromise()
        .then((res) => {

          resolve(res);
          this.messageService.add({
            severity: 'success',
            summary: 'Validation réussie',
            detail: '',
          });

        })
        .catch((err) => {
          this.handleApiError(err);
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de validation',
            detail: err,
          });
  
          throw err;
        });
    });
    
    return promise;
  }
  updatefacture(data) {
    let promise = new Promise<any>((resolve) => {
      let url = `${environment.url}/updateVente`; 
      this.http
        .post(url, data)
        .toPromise()
        .then((res) => {

          resolve(res);
          this.messageService.add({
            severity: 'success',
            summary: 'Validation réussie',
            detail: '',
          });

        })
        .catch((err) => {
          this.handleApiError(err);
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de validation',
            detail: err,
          });
  
          throw err;
        });
    });
    
    return promise;
  }
  
}
